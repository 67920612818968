import type { MutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import { handleJamError } from '@jane/ad-manager/util';

import { brandServerApi } from '../brandServerApi';
import type { ApiFlightDetails } from '../productBrands/schemas';
import type { AdSubmission, JamApiAd, JamApiError } from './types';

const urls = {
  submitAd: (brandId?: number) => `/jam/api/onboard/${brandId}`,
  flight: (flightId?: number) => `/jam/api/flight/${flightId}`,
};

const submitAd = async (adData: JamApiAd, brandId?: number) => {
  return await brandServerApi.post<AdSubmission>(
    urls.submitAd(brandId),
    adData
  );
};

export const useSubmitAd = (brandId?: number) => {
  return useMutation({
    mutationFn: (adData: JamApiAd) => submitAd(adData, brandId),
    onError: (error: Error) => handleJamError(error),
  });
};

interface FlightUpdateParams {
  end_date: string;
  is_active: boolean;
  is_read_only: boolean;
}
interface FlightUpdateMutationOpts extends FlightUpdateParams {
  id: number;
}
export const updateFlight = async (
  params: FlightUpdateParams,
  flightId?: number
) => brandServerApi.patch<ApiFlightDetails>(urls.flight(flightId), params);
type UseUpdateFlightOpts = Pick<
  MutationOptions<ApiFlightDetails, JamApiError, FlightUpdateMutationOpts>,
  'onSuccess' | 'onError'
>;
export const useUpdateFlight = ({
  onSuccess,
  onError,
}: UseUpdateFlightOpts = {}) => {
  return useMutation<ApiFlightDetails, JamApiError, FlightUpdateMutationOpts>({
    mutationFn: ({ id, ...params }: FlightUpdateMutationOpts) => {
      return updateFlight(params, id);
    },
    onSuccess,
    onError,
  });
};
