import type { NewAssetType } from '@jane/ad-manager/types';
import { uploadBrandImage } from '@jane/ad-manager/util';
import { FileUploadArea } from '@jane/shared-b2b/components';
import { Banner, Flex, InfoIcon, Link, Typography } from '@jane/shared/reefer';

import { BannerContainer } from '../Logo/LogoModal';

interface Props {
  addCustomImage: (file: string) => void;
  addCustomVideo: (file: string) => void;
  isUploading: boolean;
  newAssetType: NewAssetType;
  setError: (error: string) => void;
  setIsUploading: (isUploading: boolean) => void;
}

export const MediaUpload = ({
  newAssetType,
  addCustomImage,
  addCustomVideo,
  setError,
  isUploading,
  setIsUploading,
}: Props) => {
  const onDrop = async (acceptedFiles: Array<File>) => {
    setError('');
    if (acceptedFiles.length === 0) return;
    setIsUploading(true);
    const file = acceptedFiles[0];
    try {
      const imageSrc = await uploadBrandImage(file);
      if (newAssetType === 'newImage') addCustomImage(imageSrc);
      else addCustomVideo(imageSrc);
    } catch (errorMessage: unknown) {
      setError(errorMessage as string);
    }
    setIsUploading(false);
  };
  const isNewVideo = newAssetType === 'newVideo';

  return (
    <Flex flexDirection="column" mt={40} gap={16}>
      <Flex flexDirection="column">
        <Typography>
          {isNewVideo ? 'Video cover image preview' : 'Preview'}
        </Typography>
      </Flex>
      <FileUploadArea
        label={
          isNewVideo ? 'Upload video cover image from computer' : undefined
        }
        dragLabel={isNewVideo ? 'Drag and drop video cover here' : undefined}
        isLoading={isUploading}
        onDrop={onDrop}
      />
      <BannerContainer>
        <Banner
          full
          icon={<InfoIcon />}
          label={
            <Typography>
              Learn more about supported image dimensions{' '}
              <Link href="https://docs.iheartjane.com/brand-partner-success/brand-registration-1/brand-logo-custom-image-description-and-video#lifestyle-image-cover-image">
                here.
              </Link>
            </Typography>
          }
        />
      </BannerContainer>
    </Flex>
  );
};
