import { brandsPaths } from '@jane/brands/util';
import {
  Button,
  List,
  MoreIcon,
  Popover,
  Typography,
} from '@jane/shared/reefer';

import type {
  SpecialsTableRow,
  SpecialsTableRowActions,
} from './specialsTable.types';

interface ActionsPopoverProps {
  onDuplicate: SpecialsTableRowActions['onDuplicate'];
  onSetEnabled: SpecialsTableRowActions['onSetEnabled'];
  row: SpecialsTableRow;
}

export const ActionsPopover = ({
  onDuplicate,
  onSetEnabled,
  row,
}: ActionsPopoverProps) => {
  const label = `View actions for ${row.original.title}`;
  const elementId = `actions-popover-${row.original.title}`;
  const rowStatus = row.original.enabled ? 'Disable' : 'Enable';

  return (
    <Popover label={label} target={<Button.Icon icon={<MoreIcon />} />}>
      <Popover.Content padding={false} id={elementId}>
        <List label="List of actions" py={16}>
          <List.Item
            to={brandsPaths.specialDetail(row.original.id.toString())}
            ariaLabel="View special"
            px={24}
          >
            View special
          </List.Item>
          <List.Item
            onClick={() => onDuplicate(row)}
            ariaLabel="Duplicate"
            px={24}
          >
            Duplicate
          </List.Item>
          <List.Item
            onClick={() => onSetEnabled(row)}
            ariaLabel={rowStatus}
            px={24}
          >
            <Typography
              color={row.original.enabled ? 'error' : 'success'}
              variant="body-bold"
            >
              {rowStatus}
            </Typography>
          </List.Item>
        </List>
      </Popover.Content>
    </Popover>
  );
};

export const StatesPopover = ({ row }: { row: SpecialsTableRow }) => {
  return (
    <Popover
      label={`View targeted states for ${row.original.title}`}
      target={<Typography my={16}>5 states</Typography>}
      openOn="hover"
      alignment={{ horizontal: 'right' }}
    >
      <Popover.Content>
        <Typography>comma separated list of state names</Typography>
      </Popover.Content>
    </Popover>
  );
};
