import { getPastDate, getToday } from '@jane/gold-manager/util';
import type { FormRadioFieldGroupOption } from '@jane/shared/reefer';

import type { MultiSelectOption } from './multiSelectFilter';
import { FilterListField, FilterListItem } from './specialsFilter.styles';

export const DATE_RANGE_MAP: { [key: string]: [string, string] } = {
  '14': [getPastDate(14), getToday()],
  '30': [getPastDate(30), getToday()],
  '90': [getPastDate(90), getToday()],
};

export const DATE_RANGE_OPTIONS: FormRadioFieldGroupOption[] = [
  {
    id: 'past-14-days',
    label: 'Past 14 days',
    value: '14',
    wrapper: (children: React.ReactNode) => (
      <FilterListItem>
        <FilterListField>{children}</FilterListField>
      </FilterListItem>
    ),
  },
  {
    id: 'past-30-days',
    label: 'Past 30 days',
    value: '30',
    wrapper: (children: React.ReactNode) => (
      <FilterListItem>
        <FilterListField>{children}</FilterListField>
      </FilterListItem>
    ),
  },
  {
    id: 'past-90-days',
    label: 'Past 90 days',
    value: '90',
    wrapper: (children: React.ReactNode) => (
      <FilterListItem>
        <FilterListField>{children}</FilterListField>
      </FilterListItem>
    ),
  },
  {
    id: 'custom-date-range',
    label: 'Custom date range',
    value: 'custom',
    wrapper: (children: React.ReactNode) => (
      <FilterListItem>
        <FilterListField>{children}</FilterListField>
      </FilterListItem>
    ),
  },
];

export const DISCOUNT_TYPE_OPTIONS: MultiSelectOption[] = [
  {
    label: 'Percent',
    value: 'percent',
  },
  {
    label: 'Dollar',
    value: 'dollar',
  },
  {
    label: 'Targeted price',
    value: 'targeted_price',
  },
];

export const STATUS_OPTIONS: MultiSelectOption[] = [
  {
    label: 'Live',
    value: 'live',
  },
  { label: 'Upcoming', value: 'upcoming' },
  {
    label: 'Expired',
    value: 'expired',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
];

export const BUDGET_RANGE_MAP: { [key: string]: [number, number] } = {
  '1-5': [1, 5],
  '5-50': [5, 50],
  '50-500': [50, 500],
  '500-1000': [500, 1000],
};

export const BUDGET_RANGE_OPTIONS: { label: string; value: string }[] = [
  {
    label: '$1-$5',
    value: '1-5',
  },
  {
    label: '$5-$50',
    value: '5-50',
  },
  {
    label: '$50-$500',
    value: '50-500',
  },
  {
    label: '$500-$1000',
    value: '500-1000',
  },
];
