import { flexRender } from '@tanstack/react-table';

import { Table } from '@jane/shared/reefer-table';

import {
  Container,
  HeaderCell,
  Row,
  StyledTable,
} from './specialsTable.styles';
import type { SpecialsTableProps } from './specialsTable.types';
import { useSpecialsTable } from './useSpecialsTable';

export const SpecialsTable = ({ bulkEdit, specials }: SpecialsTableProps) => {
  const specialsTable = useSpecialsTable({
    bulkEdit: bulkEdit || false,
    data: specials || [],
    onDuplicate: () => console.log('duplicate'),
  });

  const tableHeaders = specialsTable
    .getFlatHeaders()
    .filter((header) => header.column.getIsVisible());

  return (
    <Container overflow="scroll" height="100%" minHeight="80vh">
      <StyledTable scrollable={false}>
        <colgroup>
          <col id="actions" style={{ width: '72px' }} />
          <col id="brand" style={{ width: '220px' }} />
          <col id="title" style={{ width: '220px' }} />
          <col id="status" style={{ width: '112px' }} />
          <col id="specialId" style={{ width: '112px' }} />
          <col id="startTime" style={{ width: '112px' }} />
          <col id="endTime" style={{ width: '112px' }} />
          <col id="discount" style={{ width: '112px' }} />
          <col id="budget" style={{ width: '220px' }} />
          <col id="states" style={{ width: '148px' }} />
        </colgroup>
        <Table.Head>
          <Table.Row>
            {tableHeaders.map((header) => (
              <HeaderCell key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </HeaderCell>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {specialsTable.getSortedRowModel().rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </Table.Body>
      </StyledTable>
    </Container>
  );
};
