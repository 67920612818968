import type { Brand } from '@jane/gold-manager/types';
import { Card, Form, Typography } from '@jane/shared/reefer';

import { SpecialFormBlock } from '../specialForm.styles';
import { BrandsDropdown } from './brandsDropdown';
import { ImageUpload } from './imageUpload';

interface DetailsSectionProps {
  brands: Brand[];
}

export const DetailsSection = ({ brands }: DetailsSectionProps) => {
  return (
    <Card width="100%">
      <Card.Group width="100%">
        <SpecialFormBlock first>
          <Typography variant="header-bold" branded mb={32}>
            Details
          </Typography>
          <BrandsDropdown brands={brands} />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Form.TextField
            name="title"
            label="Title"
            disableMobileInputStyling
          />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Form.TextField
            name="description"
            label="Description"
            disableMobileInputStyling
          />
        </SpecialFormBlock>

        <SpecialFormBlock bottomPadding={'16px'}>
          <Typography mb={24} variant="body-bold">
            Type
          </Typography>
          <Form.RadioFieldGroup
            name="type"
            options={[
              { id: 'type-new', label: 'New', value: 'new' },
              { id: 'type-lapsed', label: 'Lapsed', value: 'lapsed' },
              {
                id: 'type-untargeted',
                label: 'Untargeted',
                value: 'untargeted',
              },
            ]}
          />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <ImageUpload />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Form.TextAreaField
            name="terms"
            label="Terms"
            rows={4}
            resize={false}
            defaultValue={`Getting cash back requires three steps: \n1) Join by signing in or creating a Jane account\n2) Link your bank account quickly and safely\n3) Earn by purchasing a qualifying product from a participating brand. While supplies last.`}
            disableMobileInputStyling
          />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Typography mb={12} variant="body-bold">
            Aeropay Location ID <Typography as="span">(Optional)</Typography>
          </Typography>
          <Form.TextField
            name="aeropayLocationId"
            label="Aeropay Location ID"
            labelHidden
            disableMobileInputStyling
          />
        </SpecialFormBlock>
      </Card.Group>
    </Card>
  );
};
