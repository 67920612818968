import styled from '@emotion/styled';
import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';

import { marketplacePaths } from '@jane/brands/util';
import type { ProductListItem } from '@jane/catalog-cms/data-access';
import { shortDateFormat } from '@jane/catalog-cms/util';
import {
  ExternalIcon,
  Flex,
  Image,
  Link,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import { StatusCell } from './StatusCell';

type ProductProps =
  | 'id'
  | 'productId'
  | 'name'
  | 'category'
  | 'subcategory'
  | 'categoryLabel'
  | 'brand'
  | 'status'
  | 'imageUrl'
  | 'createdTime'
  | 'updatedTime'
  | 'storeCount';

// Custom typography component to limit product name to max 250px and 2 lines
const ProductNameTypography = styled(Typography)({
  whiteSpace: 'pre-line',
  width: '250px',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  // maxHeight is fallback if line clamp isn't supported
  maxHeight: '2lh',
});

// Applies color conditionally to gray out non-active product rows
const StyledTableRow = styled(Table.Row)<{ status?: string }>(
  ({ status, theme }) => ({
    color:
      status === 'Active' ? theme.colors.grays.black : theme.colors.grays.mid,
  })
);

export interface ProductsListRowProps {
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  /** Product object */
  product: Pick<ProductListItem, ProductProps>;
  /** Controls background color for the selected row.  */
  selected: boolean;
}

export const ProductsListRow = ({
  product,
  onClick,
  selected,
}: ProductsListRowProps) => {
  const createdTime = shortDateFormat(new Date(product.createdTime));
  const updatedTime = shortDateFormat(new Date(product.updatedTime));
  const toast = useToast();

  const copyProductId = useCallback(
    async (productId: number | string) => {
      try {
        await navigator.clipboard.writeText(`${productId}`);
        toast.add({
          variant: 'success',
          label: `Product ID ${productId} copied to clipboard`,
        });
      } catch (error) {
        toast.add({
          variant: 'error',
          label: 'Product ID could not be copied to clipboard',
        });
      }
    },
    [toast]
  );

  return (
    <StyledTableRow
      onClick={onClick}
      selected={selected}
      data-testid={`product-row-${product.productId}`}
      status={product.status}
    >
      <Table.Cell>
        <Flex alignItems="stretch" justifyContent="space-between" gap={24}>
          <Flex alignItems="center" gap={24}>
            <Image
              key={product.imageUrl}
              height="48px"
              width="48px"
              borderRadius="12px"
              border={true}
              objectFit="cover"
              altText={`${product.name} image`}
              src={product.imageUrl || ''}
              responsive
            />
            <ProductNameTypography
              as="span"
              variant="body-bold"
              data-testid={`product-${product.productId}-name`}
              truncateAt={'250px'}
              title={product.name}
              color="inherit"
            >
              {product.name}
            </ProductNameTypography>
          </Flex>
          <Flex alignItems="center">
            <MarketplaceLink {...product} />
          </Flex>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Link
          onClick={(e) => {
            e.stopPropagation();
            copyProductId(product.productId);
          }}
        >
          {product.productId}
        </Link>
      </Table.Cell>
      <Table.Cell>{product.category}</Table.Cell>
      <Table.Cell>{product.subcategory}</Table.Cell>
      <Table.Cell>
        <Typography
          truncateAt="200px"
          title={product.categoryLabel || undefined}
          color="inherit"
        >
          {product.categoryLabel}
        </Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography
          truncateAt="200px"
          title={product.brand || undefined}
          color="inherit"
        >
          {product.brand}
        </Typography>
      </Table.Cell>
      <StatusCell status={product.status} />
      <Table.Cell>{createdTime}</Table.Cell>
      <Table.Cell>{updatedTime}</Table.Cell>
      <Table.Cell>{product.storeCount}</Table.Cell>
    </StyledTableRow>
  );
};

/**
 * Generates a link to the product on the jane marketplace with appropriate icon
 */

const MarketplaceLink = ({
  productId,
  name,
}: Pick<ProductListItem, 'productId' | 'name'>) => {
  const url = marketplacePaths.productPage(productId, name);
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
      data-testid={`productlist-marketplace-link-${productId}`}
      style={{ color: 'inherit' }}
    >
      <ExternalIcon
        title="View on Jane"
        altText="View product on Jane marketplace (opens in new tab)"
        color="inherit"
        size="md"
      />
    </a>
  );
};
