import { useQuery } from '@tanstack/react-query';

import { handleJamError } from '@jane/ad-manager/util';
import { useAuth } from '@jane/brands/hooks';
import type { ApiRequestError } from '@jane/shared/data-access';

import { brandServerApi } from '../brandServerApi';
import type { ApiFlightDetails } from '../productBrands/schemas';

export const urls = {
  getFlightDetail: (flightId: string) => `/jam/api/flight/${flightId}`,
};

type GetFlightDetailKey = FetchFlightDetailArgs;
const keys = {
  all: ['ads'] as const,
  getFlightDetail: (args?: GetFlightDetailKey) => [
    ...keys.all,
    'get-flight-detail',
    args,
  ],
};

export interface FetchFlightDetailArgs {
  endDate?: string | null;
  flightId: number;
  startDate?: string | null;
}
export const fetchFlightDetail = async ({
  flightId,
  endDate,
  startDate,
}: FetchFlightDetailArgs): Promise<ApiFlightDetails> => {
  const params = new URLSearchParams();
  if (startDate) params.set('start_date', startDate);
  if (endDate) params.set('end_date', endDate);

  return await brandServerApi.get(
    `${urls.getFlightDetail(flightId.toString())}?${params}`
  );
};

export const useGetFlightDetail = (args?: FetchFlightDetailArgs) => {
  const { userQuery } = useAuth();
  const userId = userQuery.data?.id;
  return useQuery({
    queryKey: keys.getFlightDetail(args),
    // Will not execute if `args` is undefined
    queryFn: () => fetchFlightDetail(args as FetchFlightDetailArgs),
    enabled: userId !== undefined && args?.flightId !== undefined,
    refetchOnWindowFocus: false,
    onError: (error: ApiRequestError) => handleJamError(error),
    retry: false,
  });
};
