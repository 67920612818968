import { ThemeProvider } from '@emotion/react';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import { USER_QUERY_DEFAULTS, USER_QUERY_KEY } from '@jane/brands/hooks';
import { BRAND_PORTAL_THEME } from '@jane/brands/util';
import { initDmMixpanel } from '@jane/dm/internal';
import { config } from '@jane/shared/config';
import { asyncFlagProvider } from '@jane/shared/feature-flags';
import { ReeferThemeProvider, ToastProvider } from '@jane/shared/reefer';
import { initDatadogRum, initMixpanel } from '@jane/shared/util';

import { App } from './app/App';
import { initTrackJSBrands } from './trackjs';

// Disables trackJS in local dev environment
if (document.location.host.indexOf('localhost') !== 0) {
  initTrackJSBrands();
}

// Initialize mixpanel
initMixpanel(config.brandMixpanelToken, { useLocalStorage: true });

// Initialize DM mixpanel
let initializedDMMixpanel = false;
if (!initializedDMMixpanel) {
  initDmMixpanel({
    appMode: 'ad-manager',
    storeId: null,
    janeDeviceId: null,
  });

  initializedDMMixpanel = true;
}

// Initialize DataDog
let initializedDataDog = false;
if (!initializedDataDog) {
  initDatadogRum({
    allowedTracingUrls: [
      (url) => url.startsWith(process.env.NX_BRAND_PORTAL_URL || ''),
    ],
    applicationId: process.env.NX_BRANDS_DATADOG_APP_ID || '',
    enableExperimentalFeatures: ['feature_flags'],
    clientToken: process.env.NX_BRANDS_DATADOG_CLIENT_TOKEN || '',
    defaultPrivacyLevel: 'allow' as const,
    service: 'brands',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 50,
    traceSampleRate: 50,
  });

  initializedDataDog = true;
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
const queryClient = new QueryClient();

// == CUSTOM QUERY DEFAULTS ==

// Applying custom query defaults here lets our test wrapper overwrite these
// defaults while testing. Eg. Setting retries to false for tests. See
// https://tkdodo.eu/blog/testing-react-query#setquerydefaults

queryClient.setQueryDefaults(USER_QUERY_KEY, USER_QUERY_DEFAULTS);

(async () => {
  const FlagProvider = await asyncFlagProvider(
    config.brandLaunchDarklyClientID
  );
  root.render(
    <StrictMode>
      <FlagProvider>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <ReeferThemeProvider
              renderProvider={(theme, children) => (
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
              )}
              theme={BRAND_PORTAL_THEME}
            >
              <ToastProvider verticalOffset={8} position="top-middle">
                <App />
              </ToastProvider>
            </ReeferThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </HelmetProvider>
      </FlagProvider>
    </StrictMode>
  );
})();
