import styled from '@emotion/styled';
import { useMemo, useState } from 'react';

import type { Brand } from '@jane/gold-manager/types';
import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  AlertIcon,
  ChevronDownIcon,
  Flex,
  List,
  PartnerLogo,
  Popover,
  SearchField,
  Typography,
} from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';

const StyledPopover = styled(Popover)(() => ({
  '> *': { width: '100%' },
}));

interface BrandsDropdownProps {
  brands: Brand[];
}

export const BrandsDropdown = ({ brands }: BrandsDropdownProps) => {
  const [selected, setSelected] = useState<Brand | undefined>(undefined);
  const [query, setQuery] = useState('');

  const filteredBrands = useMemo(
    () =>
      brands
        .filter((brand) =>
          brand.name.toLowerCase().includes(query.toLowerCase())
        )
        .sort(function (a, b) {
          return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
        }),
    [brands, query]
  );

  return (
    <>
      <Typography variant="body-bold" mb={12}>
        Brand
      </Typography>
      <StyledPopover
        label="See brands to select from"
        disableMobileStyling
        targetWidth="100%"
        onClose={() => setQuery('')}
        target={
          <Flex
            borderRadius="xs"
            border="grays-light"
            py={8}
            px={16}
            height={48}
            flexDirection="row"
            alignItems="center"
            style={{ boxShadow: shadows.drop }}
          >
            {selected && (
              <PartnerLogo
                name={selected.name}
                variant="brand"
                size="xxs"
                image={selected.logoUrl}
              />
            )}
            <Typography
              ml={selected ? 12 : 0}
              color={selected ? 'grays-black' : 'grays-mid'}
            >
              {selected ? selected.name : 'Select brand'}
            </Typography>
            <ChevronDownIcon ml="auto" />
          </Flex>
        }
      >
        {({ closePopover }: PopoverContextProps) => (
          <Popover.Content
            label="Select a brand from the list or search by name"
            maxHeight="490px"
            padding={false}
          >
            <Flex flexDirection="column" pt={24} pb={16}>
              <SearchField
                borderRadius="sm"
                label="Search brands"
                name="brand-search-field"
                onChange={(value) => setQuery(value)}
                placeholder="Search brands"
                mb={8}
                mx={24}
                disableMobileInputStyling
              />
              {filteredBrands.length > 0 ? (
                <List label="List of brands">
                  {filteredBrands.map((brand) => (
                    <List.Item
                      key={brand.id}
                      px={24}
                      py={8}
                      onClick={() => {
                        setSelected(brand);
                        setQuery('');
                        closePopover();
                      }}
                      aria-label={brand.name}
                    >
                      <Flex flexDirection="row" alignItems="center" gap={12}>
                        <PartnerLogo
                          name={brand.name}
                          variant="brand"
                          size="xs"
                          image={brand.logoUrl}
                        />
                        <Typography>{brand.name}</Typography>
                      </Flex>
                    </List.Item>
                  ))}
                </List>
              ) : query ? (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  gap={12}
                  py={24}
                >
                  <AlertIcon />
                  <Typography>No brands matched "{query}"</Typography>
                </Flex>
              ) : null}
            </Flex>
          </Popover.Content>
        )}
      </StyledPopover>
    </>
  );
};
