import type { QueryFunctionContext } from '@tanstack/react-query';
import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

import { STATUS_ENUM } from './fetchProducts';

const ProductLocalizationSchema = z.object({
  id: z.string().uuid(),
  country: z.string(),
  subdivision: z.string(),
  label: z.string(),
});

const EditFormProductSchema = z.object({
  id: z.string().uuid(),
  productId: z.number().int().positive(),
  brandId: z.number().int().positive().nullable(),
  name: z.string(),
  category: z.string().nullable(),
  categoryLabel: z.string().nullable(),
  subcategory: z.string().nullable(),
  lineage: z.string().nullable(),
  description: z.string(),
  imageURLs: z.array(z.string().url()),
  status: z.enum(STATUS_ENUM),
  createdTime: z.string().datetime(),
  updatedTime: z.string().datetime(),
  amount: z.string().nullable(),
  dosage: z.string().nullable(),
  thcDosageMilligrams: z.string().nullable(),
  cbdDosageMilligrams: z.string().nullable(),
  reportedTime: z.string().datetime().nullable(),
  reportData: z
    .object({
      activeProductId: z.string().uuid().optional(),
      comment: z.string().optional(),
      activeProduct: z
        .object({ name: z.string(), id: z.number().int().positive() })
        .optional(),
    })
    .nullable(),
  localizations: z.array(ProductLocalizationSchema),
  storeCount: z.number().nonnegative(),
});

const EditFormProductResponseSchema = z.object({ data: EditFormProductSchema });

/**
 * Product object with data specifically tailored to the edit product flow
 */
export type EditFormProduct = z.infer<typeof EditFormProductSchema>;
export type EditFormProductBody = z.infer<typeof EditFormProductResponseSchema>;
export type ProductLocalization = z.infer<typeof ProductLocalizationSchema>;

/**
 * Fetch function for the edit form product query.
 */
export const fetchEditFormProduct = async ({
  queryKey,
}: QueryFunctionContext<[string, string, { uuid: string }]>) => {
  const { uuid } = queryKey[2];
  const { data } = await brandsApi.get(brandServerPaths.productEditForm(uuid));

  const parsedData = parseData(EditFormProductResponseSchema, data);
  return parsedData.data;
};
