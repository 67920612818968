import { useMemo } from 'react';

import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Button,
  CheckboxField,
  ChevronDownIcon,
  Flex,
  Popover,
} from '@jane/shared/reefer';

import { ActiveFilterTag } from './activeFilterTag';
import { DeselectFiltersButton } from './deselectFiltersButton';
import {
  FilterListField,
  FilterListItem,
  StyledFilterList,
} from './specialsFilter.styles';

export interface MultiSelectOption {
  label: string;
  value: string | number;
}

export interface MultiSelectFilterProps {
  currentValue: (string | number)[];
  handleChange: (updatedValue: (string | number)[]) => void;
  label: string;
  options: MultiSelectOption[];
}

export const MultiSelectFilter = ({
  label,
  options,
  currentValue,
  handleChange,
}: MultiSelectFilterProps) => {
  const currentValueSet = useMemo(() => new Set(currentValue), [currentValue]);

  // Generate onChange function for each checkbox value
  const generateOnChange = (value: MultiSelectOption['value']) => {
    return (checked: boolean) => {
      // only update the state if necessary, updates are idempotent
      if (checked === true && !currentValueSet.has(value)) {
        const updatedValues = new Set(currentValueSet);

        updatedValues.add(value);

        handleChange(Array.from(updatedValues));
      } else if (checked === false && currentValueSet.has(value)) {
        const updatedValues = new Set(currentValueSet);

        updatedValues.delete(value);

        handleChange(Array.from(updatedValues));
      }
    };
  };

  return (
    <Popover
      target={
        <Button
          label={label}
          variant={
            currentValueSet.size === 0 ? 'tertiary' : 'tertiary-selected'
          }
          endIcon={
            <Flex alignItems="center">
              <ActiveFilterTag
                valuesCount={currentValueSet.size}
                label={label}
              />
              <ChevronDownIcon color="inherit" size="sm" />
            </Flex>
          }
        />
      }
      label={label}
      disableMobileStyling
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content padding={false}>
          <StyledFilterList aria-label={`${label} options`}>
            {options?.map(({ label, value }) => (
              <FilterListItem key={value} aria-label={label}>
                <FilterListField>
                  <CheckboxField
                    label={label}
                    name={label}
                    checked={currentValueSet.has(value)}
                    onChange={generateOnChange(value)}
                  />
                </FilterListField>
              </FilterListItem>
            ))}
            <DeselectFiltersButton
              onClick={() => {
                handleChange([]);
                closePopover();
              }}
              label="Deselect all"
            />
          </StyledFilterList>
        </Popover.Content>
      )}
    </Popover>
  );
};
