import { Flex } from '@jane/shared/reefer';

import { BudgetRangeFilter } from './budgetRangeFilter';
import { DateRangeFilter } from './dateRangeFilter';
import { DeselectFiltersButton } from './deselectFiltersButton';
import { MultiSelectFilter } from './multiSelectFilter';
import {
  DISCOUNT_TYPE_OPTIONS,
  STATUS_OPTIONS,
} from './specialsFiltersOptions';

export const SpecialsFilters = () => {
  return (
    <Flex width="100%" gap={16} alignItems="center" flexWrap="wrap">
      <DateRangeFilter
        currentValue={['2024-11-21', '2024-11-22']}
        handleChange={(value) => console.log(value)}
      />
      <MultiSelectFilter
        currentValue={[]}
        handleChange={(value) => console.log(value)}
        label="Discount"
        options={DISCOUNT_TYPE_OPTIONS}
      />
      <BudgetRangeFilter
        currentValue={[]}
        handleChange={(value) => console.log(value)}
      />
      <MultiSelectFilter
        currentValue={[]}
        handleChange={(value) => console.log(value)}
        label="Status"
        options={STATUS_OPTIONS}
      />
      <DeselectFiltersButton
        onClick={() => console.log('clear filters')}
        label="Clear filters"
        inList={false}
      />
    </Flex>
  );
};
