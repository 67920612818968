import styled from '@emotion/styled';
import { flexRender } from '@tanstack/react-table';

import { Box } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import type {
  SpecialsTableCell,
  SpecialsTableRow,
} from './specialsTable.types';

export const Container = styled(Box)({
  scrollbarWidth: 'none',
});

export const StyledTable = styled(Table)({
  tableLayout: 'fixed',
  width: '100%',
});

export const HeaderCell = styled(Table.HeaderCell)({
  width: '100%',
  '> div': {
    width: '100%',
  },
});

export const Row = ({ row }: { row: SpecialsTableRow }) => (
  <Table.Row data-testid={`row-special-${row.original.title}`}>
    {row.getVisibleCells().map((cell: SpecialsTableCell) => (
      <Table.Cell key={cell.id}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Table.Cell>
    ))}
  </Table.Row>
);
