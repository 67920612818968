import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const UTC_TIMEZONE = 'UTC';
export const OUTPUT_FORMAT = 'MM/DD/YYYY h:mm A';
export const RFC3339_FORMAT_STRING = 'YYYY-MM-DDTHH:mm:ss[Z]' as const;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const nowInUTC = () => dayjs().tz(UTC_TIMEZONE);

export const userTimezone = (): string => dayjs.tz.guess();

export const isFutureDate = (dateString: string): boolean => {
  const date = dayjs(dateString);
  const now = nowInUTC();
  return date.isAfter(now);
};

export const isPastDate = (dateString: string): boolean => {
  const date = dayjs(dateString);
  const now = nowInUTC();
  return date.isBefore(now);
};

export const getPastDate = (daysAgo: number): string =>
  dayjs().subtract(daysAgo, 'days').format('YYYY-MM-DD');

export const getToday = (): string => dayjs().format('YYYY-MM-DD');
