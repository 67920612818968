/** @todo delete when factory is set up */
import type { Brand } from '@jane/gold-manager/types';

export const MOCK_BRANDS_DATA: Brand[] = [
  {
    id: 1,
    name: 'Grant, West and Watsica',
    logoUrl: 'https://picsum.photos/seed/ocGIQD/500/500',
  },
  {
    id: 2,
    name: 'Heathcote and Sons',
    logoUrl: '',
  },
  {
    id: 3,
    name: 'Konopelski - Will',
    logoUrl: 'https://loremflickr.com/500/500?lock=7541454872248320',
  },
  {
    id: 4,
    name: "Schoen, Friesen and D'Amore",
    logoUrl: 'https://loremflickr.com/500/500?lock=5253848423727104',
  },
  {
    id: 5,
    name: 'Huels - Will',
  },
  {
    id: 6,
    name: 'Hyatt Group',
    logoUrl: 'https://loremflickr.com/500/500?lock=6547301491277824',
  },
  {
    id: 7,
    name: 'Hintz, Kovacek and Witting',
  },
];
