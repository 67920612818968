import styled from '@emotion/styled';

import { Box } from '@jane/shared/reefer';

export const SpecialFormWrapper = styled.div(() => ({
  maxWidth: 944,
  minWidth: 550,
  margin: '0 auto',
}));

export const SpecialFormBlock = styled(Box)<{
  bottomPadding?: string;
  first?: boolean;
}>(({ bottomPadding = '40px', first = false, theme }) => ({
  backgroundColor: theme.colors.grays.white,
  borderColor: theme.colors.grays.light,
  borderStyle: 'solid',
  borderWidth: `${first ? '0' : '1px'} 0 0 0`,
  padding: `40px 40px ${bottomPadding}`,
}));
