import type { Brand } from '@jane/gold-manager/types';
import { Flex, Form } from '@jane/shared/reefer';

import { BudgetSection } from './budgetSection/budgetSection';
import { DetailsSection } from './detailsSection/detailsSection';
import { ProductsSection } from './productsSection/productsSection';
import { SpecialFormWrapper } from './specialForm.styles';

interface SpecialFormProps {
  brands: Brand[];
}
export const SpecialForm = ({ brands }: SpecialFormProps) => {
  return (
    <SpecialFormWrapper>
      <Form
        name="brand-special-form"
        onSubmit={() => {
          return;
        }}
      >
        <Flex flexDirection="column" gap={24}>
          <DetailsSection brands={brands} />
          <BudgetSection />
          <ProductsSection />
        </Flex>
      </Form>
    </SpecialFormWrapper>
  );
};
