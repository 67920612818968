import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import { useFlags } from '@jane/shared/feature-flags';
import { ObjectKeys } from '@jane/shared/util';

import { routes } from './routes';

const router = createBrowserRouter(routes);

export function App() {
  const flags = useFlags();

  useEffect(() => {
    ObjectKeys(flags).forEach((key) => {
      datadogRum.addFeatureFlagEvaluation(key, flags[key]);
    });
  }, [flags]);

  return <RouterProvider router={router} />;
}

export default App;
