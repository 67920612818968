import { useState } from 'react';

import { Card, Flex, Form, Typography } from '@jane/shared/reefer';

import { SpecialFormBlock } from '../specialForm.styles';

export const BudgetSection = () => {
  const [selected, setSelected] = useState('');

  return (
    <Card width="100%">
      <Card.Group width="100%">
        <SpecialFormBlock first>
          <Typography variant="header-bold" branded mb={32}>
            Budget
          </Typography>
          <Flex flexDirection="column" gap={24}>
            <Typography variant="body-bold">Discount type</Typography>
            <Form.RadioFieldGroup
              name="discount-type"
              onChange={setSelected}
              options={[
                {
                  id: 'discount-type-dollar',
                  label: 'Dollar',
                  value: 'dollar',
                  wrapper: (children) => (
                    <>
                      {children}
                      {selected === 'dollar' && (
                        <Flex flexDirection="column" gap={12} mx={24} px={12}>
                          <Form.NumberField
                            width="calc(50% - 12px)"
                            name="dollarAmount"
                            label="Amount"
                            disableMobileInputStyling
                            startUnit="$"
                            allowedDecimalPlaces={2}
                            max={20}
                          />
                          <Typography color="grays-mid">
                            Cannot exceed $20.00
                          </Typography>
                        </Flex>
                      )}
                    </>
                  ),
                },
                {
                  id: 'discount-type-percent',
                  label: 'Percent',
                  value: 'percent',
                  wrapper: (children) => (
                    <>
                      {children}
                      {selected === 'percent' && (
                        <Flex flexDirection="column" gap={12} mx={24} px={12}>
                          <Form.NumberField
                            width="calc(50% - 12px)"
                            name="percentAmount"
                            label="Amount"
                            disableMobileInputStyling
                            endUnit="%"
                            max={99}
                          />
                          <Typography color="grays-mid">
                            Cannot exceed 99%
                          </Typography>
                        </Flex>
                      )}
                    </>
                  ),
                },
                {
                  id: 'discount-type-target-price',
                  label: 'Target price',
                  value: 'target-price',
                  wrapper: (children) => (
                    <>
                      {children}
                      {selected === 'target-price' && (
                        <Flex flexDirection="column" gap={12} mx={24} px={12}>
                          <Form.NumberField
                            width="calc(50% - 12px)"
                            name="targetPriceAmount"
                            label="Amount"
                            disableMobileInputStyling
                            startUnit="$"
                            allowedDecimalPlaces={2}
                          />
                        </Flex>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Flex>
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Flex flexDirection="row" width="100%" gap={24}>
            <Form.NumberField
              name="budgetTotal"
              label="Budget total"
              disableMobileInputStyling
              width="100%"
              startUnit="$"
              allowedDecimalPlaces={2}
            />
            <Flex flexDirection="column" width="100%" gap={12}>
              <Form.NumberField
                name="minimumAvailableBudget"
                label="Minimum available budget"
                disableMobileInputStyling
                startUnit="$"
                defaultValue={10}
                allowedDecimalPlaces={2}
                min={5}
              />
              <Typography color="grays-mid">
                Cannot be less than $5.00
              </Typography>
            </Flex>
          </Flex>
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Flex flexDirection="row" width="100%" gap={24}>
            <Form.NumberField
              name="managementFee"
              label="Management fee"
              disableMobileInputStyling
              width="100%"
              endUnit="%"
              defaultValue={12}
            />
            <Form.NumberField
              name="serviceFee"
              label="Service fee"
              disableMobileInputStyling
              width="100%"
              endUnit="%"
              defaultValue={15}
            />
          </Flex>
        </SpecialFormBlock>
      </Card.Group>
    </Card>
  );
};
