import styled from '@emotion/styled';

import { spacing } from '@jane/shared/reefer-emotion';

export const StyledFilterList = styled.ul({
  overflow: 'auto',
  position: 'relative',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  // ensures hover effect is seamless
  '> li:first-of-type > div > div > div': {
    ...spacing({ pt: 12 }),
  },
  '> li:last-of-type > button': {
    ...spacing({ pb: 20 }),
  },
});

export const FilterListItem = styled.li({
  listStyle: 'none',
  transition: 'all 250ms',
});

export const FilterListField = styled.div({
  position: 'relative',
  width: '100%',
  '> div > div': {
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  // label wrapper --> overrides ensure anywhere is clickable
  '> div > div > div:first-of-type': {
    height: '100%',
    width: '100%',
    label: {
      position: 'relative',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      color: 'inherit',
      ...spacing({ py: 12, m: 0, pl: 16, pr: 16 }),
    },
  },
  // input wrapper --> overrides ensure anywhere is clickable
  '> div > div > div:nth-of-type(2)': {
    width: '100%',
    maxWidth: '48px',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    input: {
      position: 'relative',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      ...spacing({ py: 12 }),
    },
    '> div': {
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
});

export const FilterListDateTimeField = styled.div(
  ({ disabled }: { disabled?: boolean }) => ({
    ...spacing({ px: 24, py: 12 }),
    ...(!disabled && {
      input: {
        cursor: 'pointer',
      },
      'input::-webkit-calendar-picker-indicator': {
        cursor: 'pointer',
      },
    }),
  })
);

export const FilterListButton = styled.button({
  outline: 'none',
  borderWidth: 0,
  textDecoration: 'none',
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
  textAlign: 'left',
  ...spacing({ py: 12, px: 24, m: 0 }),
  display: 'block',
  color: 'inherit',
  '&:hover, &:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  cursor: 'pointer',
  '> span, p': {
    cursor: 'pointer',
  },
});

export const FilterButtonGrid = styled.div({
  display: 'grid',
  gridGap: 16,
  gridAutoRows: '1fr',
  width: '100%',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  ...spacing({ px: 24, pb: 12 }),
});
