import { datadogRum } from '@datadog/browser-rum';
import type { ZodError } from 'zod';

import {
  trackFailedRequest,
  trackUnexpectedError,
  trackZodError,
} from '@jane/dm/internal';
import type { ApiRequestError } from '@jane/shared/data-access';

export type JamError = ApiRequestError | ZodError | Error;

export const handleJamError = async (error: JamError) => {
  datadogRum.addError(error);

  if ('issues' in error) {
    trackZodError({ name: error.name, message: JSON.stringify(error.issues) });
  } else if ('response' in error) {
    trackFailedRequest({
      code: error.response.status,
      error: error.response.statusText,
      attemptedUrl: error.response.url,
    });
  } else {
    trackUnexpectedError({ error });
  }
};
