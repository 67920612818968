import { createColumnHelper } from '@tanstack/react-table';

import { brandsPaths } from '@jane/brands/util';
import { getSpecialStatus } from '@jane/gold-manager/util';
import type { BrandSpecial } from '@jane/shared/models';
import { CheckboxField, Link } from '@jane/shared/reefer';
import { sentenceCase } from '@jane/shared/util';

import { SortableHeader } from './sortableHeader';
import type { SpecialsTableRowActions } from './specialsTable.types';
import {
  StatusTag,
  bodyContent,
  brandContent,
  getScheduledTime,
  headerContent,
} from './specialsTableContent';
import { ActionsPopover, StatesPopover } from './specialsTablePopovers';

const columnHelper = createColumnHelper<BrandSpecial>();

export const getColumns = ({
  bulkEdit,
  onDuplicate,
  onSetEnabled,
}: SpecialsTableRowActions) => [
  /** Selection & Actions */
  bulkEdit
    ? columnHelper.display({
        id: 'select',
        header: ({ table }) => {
          const isChecked = table.getIsAllRowsSelected();
          return (
            <CheckboxField
              checked={isChecked}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={() => {
                if (isChecked === false) {
                  table.toggleAllRowsSelected(true);
                } else {
                  table.toggleAllRowsSelected(false);
                }
              }}
              label="Select all specials"
              name="Select all specials"
              labelHidden
            />
          );
        },
        cell: ({ row }) => (
          <CheckboxField
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            label={`Select special ${row.original.id}`}
            name={`Select special ${row.original.id}`}
            labelHidden
          />
        ),
      })
    : columnHelper.display({
        id: 'actions',
        cell: ({ row }) => {
          return (
            <ActionsPopover
              row={row}
              onDuplicate={onDuplicate}
              onSetEnabled={onSetEnabled}
            />
          );
        },
      }),

  /* Brand name and logo */
  //! TO DO
  columnHelper.accessor('title', {
    id: 'brand',
    header: () => headerContent('Brand'),
    cell: ({ getValue }) =>
      brandContent({ brand: { id: 123, name: 'Test Brand', logoUrl: '' } }),
  }),

  /* Special name */
  columnHelper.accessor('title', {
    id: 'title',
    header: () => headerContent('Special name'),
    cell: ({ getValue }) => bodyContent(getValue()),
  }),

  /* Status */
  columnHelper.accessor(
    (row) =>
      getSpecialStatus({
        enabled: row.enabled,
        endTime: row.end_time,
        startTime: row.start_time,
      }),
    {
      id: 'status',
      header: ({ column }) => <SortableHeader label="Status" column={column} />,
      cell: ({ row }) => <StatusTag row={row} />,
    }
  ),

  /* Special ID */
  columnHelper.accessor(({ id }) => id.toString(), {
    id: 'id',
    header: () => headerContent('Special ID'),
    cell: ({ getValue }) => (
      <Link to={brandsPaths.specialDetail(getValue())}>{getValue()}</Link>
    ),
  }),

  /* Start time */
  columnHelper.accessor('start_time', {
    id: 'start_time',
    header: () => headerContent('Start time'),
    cell: ({ getValue }) => bodyContent(getScheduledTime(getValue())),
  }),

  /* End time */
  columnHelper.accessor('end_time', {
    id: 'end_time',
    header: () => headerContent('End time'),
    cell: ({ getValue }) => bodyContent(getScheduledTime(getValue())),
  }),

  /* Discount */
  columnHelper.accessor('discount_label', {
    id: 'discount_label',
    header: () => headerContent('Discount'),
    cell: ({ getValue }) => bodyContent(sentenceCase(getValue())),
  }),

  /* Budget available + total */
  //! TO DO
  columnHelper.accessor('discount_label', {
    id: 'budget',
    header: () => headerContent('Budget available/total'),
    cell: ({ getValue }) => bodyContent('n/a'),
  }),

  /* Targeted states */
  //! TO DO
  columnHelper.display({
    id: 'states',
    header: () => headerContent('Targeted states'),
    cell: ({ row }) => <StatesPopover row={row} />,
  }),
];
