import type { BrandSpecial } from '@jane/shared/models';

// TODO: DELETE ONCE FACTORIES ARE BUILT

export const MOCK_DATA: BrandSpecial[] = [
  {
    active: true,
    created_at: '2024-07-13T00:52:27.750Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: '80% back',
    discount_percent: 80,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: true,
    end_time: null,
    excluded_app_modes: [],
    id: 61,
    photo: null,
    product_brand_id: 22,
    rules: {
      excludes: [],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-11-21T00:00:00+00:00',
    terms: '',
    title: 'super cheeba chews',
    updated_at: '2024-11-12T20:35:20.753Z',
  },
  {
    active: true,
    created_at: '2024-07-13T00:00:29.587Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: 'cash back',
    discount_percent: 0,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: true,
    end_time: null,
    excluded_app_modes: [],
    id: 60,
    photo: null,
    product_brand_id: 70,
    rules: {
      excludes: [
        {
          user_segment_ids: [7],
        },
      ],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-07-10T10:00:00+00:00',
    terms: '',
    title: 'Exclusion Test - AbsoluteXtracts',
    updated_at: '2024-10-07T18:32:45.065Z',
  },
  {
    active: true,
    created_at: '2024-07-12T23:39:32.809Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: '8% back',
    discount_percent: 8,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: false,
    end_time: null,
    excluded_app_modes: [],
    id: 58,
    photo: null,
    product_brand_id: 16,
    rules: {
      excludes: [
        {
          user_segment_ids: [8],
        },
      ],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-07-10T10:00:00+00:00',
    terms: '',
    title: "Exclusion Special - Jane's Brew",
    updated_at: '2024-11-12T23:08:15.932Z',
  },
  {
    active: true,
    created_at: '2024-07-12T23:39:32.809Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: '8% back',
    discount_percent: 8,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: true,
    end_time: '2024-07-10T10:00:00+00:00',
    excluded_app_modes: [],
    id: 59,
    photo: null,
    product_brand_id: 16,
    rules: {
      excludes: [
        {
          user_segment_ids: [8],
        },
      ],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-07-10T10:00:00+00:00',
    terms: '',
    title: "Exclusion Special - Jane's Brew",
    updated_at: '2024-11-12T23:08:15.932Z',
  },
  {
    active: true,
    created_at: '2024-07-12T23:39:32.809Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: '8% back',
    discount_percent: 8,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: true,
    end_time: '2024-07-10T10:00:00+00:00',
    excluded_app_modes: [],
    id: 5,
    photo: null,
    product_brand_id: 16,
    rules: {
      excludes: [
        {
          user_segment_ids: [8],
        },
      ],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-07-10T10:00:00+00:00',
    terms: '',
    title: "Exclusion Special - Jane's Brew",
    updated_at: '2024-11-12T23:08:15.932Z',
  },
  {
    active: true,
    created_at: '2024-07-12T23:39:32.809Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: '8% back',
    discount_percent: 8,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: true,
    end_time: '2024-07-10T10:00:00+00:00',
    excluded_app_modes: [],
    id: 6,
    photo: null,
    product_brand_id: 16,
    rules: {
      excludes: [
        {
          user_segment_ids: [8],
        },
      ],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-07-10T10:00:00+00:00',
    terms: '',
    title: "Exclusion Special - Jane's Brew",
    updated_at: '2024-11-12T23:08:15.932Z',
  },
  {
    active: true,
    created_at: '2024-07-12T23:39:32.809Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: '8% back',
    discount_percent: 8,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: true,
    end_time: '2024-07-10T10:00:00+00:00',
    excluded_app_modes: [],
    id: 7,
    photo: null,
    product_brand_id: 16,
    rules: {
      excludes: [
        {
          user_segment_ids: [8],
        },
      ],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-07-10T10:00:00+00:00',
    terms: '',
    title: "Exclusion Special - Jane's Brew",
    updated_at: '2024-11-12T23:08:15.932Z',
  },
  {
    active: true,
    created_at: '2024-07-12T23:39:32.809Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: '8% back',
    discount_percent: 8,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: true,
    end_time: '2024-07-10T10:00:00+00:00',
    excluded_app_modes: [],
    id: 8,
    photo: null,
    product_brand_id: 16,
    rules: {
      excludes: [
        {
          user_segment_ids: [8],
        },
      ],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-07-10T10:00:00+00:00',
    terms: '',
    title: "Exclusion Special - Jane's Brew",
    updated_at: '2024-11-12T23:08:15.932Z',
  },
  {
    active: true,
    created_at: '2024-07-12T23:39:32.809Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: '8% back',
    discount_percent: 8,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: true,
    end_time: '2024-07-10T10:00:00+00:00',
    excluded_app_modes: [],
    id: 9,
    photo: null,
    product_brand_id: 16,
    rules: {
      excludes: [
        {
          user_segment_ids: [8],
        },
      ],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-07-10T10:00:00+00:00',
    terms: '',
    title: "Exclusion Special - Jane's Brew",
    updated_at: '2024-11-12T23:08:15.932Z',
  },
  {
    active: true,
    created_at: '2024-07-12T23:39:32.809Z',
    description: '',
    discount_dollar_amount: 0,
    discount_label: '8% back',
    discount_percent: 8,
    discount_target_price: 0,
    discount_type: 'percent',
    enabled: true,
    end_time: '2024-07-10T10:00:00+00:00',
    excluded_app_modes: [],
    id: 10,
    photo: null,
    product_brand_id: 16,
    rules: {
      excludes: [
        {
          user_segment_ids: [8],
        },
      ],
      includes: [],
    },
    special_type: 'product',
    start_time: '2024-07-10T10:00:00+00:00',
    terms: '',
    title: "Exclusion Special - Jane's Brew",
    updated_at: '2024-11-12T23:08:15.932Z',
  },
];
