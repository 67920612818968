import { useState } from 'react';

import {
  Banner,
  Button,
  Card,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  Form,
  InfoIcon,
  Modal,
  Typography,
} from '@jane/shared/reefer';

import { SpecialFormBlock } from '../specialForm.styles';

const BrandSpecialRules = ({
  variant,
}: {
  variant: 'inclusions' | 'exclusions';
}) => {
  const isInclusion = variant === 'inclusions';
  const defaultRadioValue = isInclusion ? 'all' : 'none';
  const defaultLabel = isInclusion ? 'All products' : 'No exclusions';
  const bannerLabel = isInclusion
    ? 'Discounted products must satisfy all inclusion rules set'
    : 'Excluded products must satisfy all exclusion rules set';

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultRadioValue);

  return (
    <>
      <SpecialFormBlock first={isInclusion}>
        {isInclusion && (
          <Typography variant="header-bold" branded mb={32}>
            Products
          </Typography>
        )}
        <Flex
          flexDirection="row"
          width="100%"
          alignItems="center"
          mb={open ? 24 : 0}
          onClick={() => setOpen((prev) => !prev)}
          aria-label={`${open ? 'Close' : 'Open'} ${variant} section`}
        >
          <Flex flexDirection="column" mb={open ? 24 : 0}>
            <Typography variant="body-bold">
              {variant[0].toUpperCase() + variant.slice(1)}
            </Typography>
            {!open && (
              <Typography>
                {selected === 'specific'
                  ? 'Specific products, category and weight'
                  : defaultLabel}
              </Typography>
            )}
          </Flex>
          {open ? <ChevronUpIcon ml="auto" /> : <ChevronDownIcon ml="auto" />}
        </Flex>
        {open && (
          <>
            <Form.RadioFieldGroup
              name={variant}
              options={[
                {
                  id: `${variant}-${defaultRadioValue}`,
                  label: defaultLabel,
                  value: `${defaultRadioValue}`,
                },
                {
                  id: `${variant}-specific`,
                  label: 'Specific products, category and weight',
                  value: 'specific',
                },
              ]}
              onChange={setSelected}
              defaultChecked={selected}
            />
            <Banner
              full
              icon={<InfoIcon />}
              label={<Typography>{bannerLabel}</Typography>}
            />
          </>
        )}
      </SpecialFormBlock>

      {open && selected === 'specific' && (
        <SpecialFormBlock>
          <Flex flexDirection="row" width="100%" alignItems="center" mb={16}>
            <Typography>Products</Typography>
            <Button
              variant="tertiary"
              label="Select"
              aria-label={`Select ${variant} products`}
              ml="auto"
            />
          </Flex>

          <Modal.ContentDivider color="grays-light" padding={false} />

          <Flex flexDirection="row" width="100%" alignItems="center" my={16}>
            <Typography>Categories</Typography>
            <Button
              variant="tertiary"
              label="Select"
              aria-label={`Select ${variant} categories`}
              ml="auto"
            />
          </Flex>

          <Modal.ContentDivider color="grays-light" padding={false} />

          <Flex flexDirection="row" width="100%" alignItems="center" mt={16}>
            <Typography>Weight</Typography>
            <Button
              variant="tertiary"
              label="All weights"
              aria-label={`Select ${variant} weights`}
              ml="auto"
              endIcon={<ChevronDownIcon size="sm" />}
            />
          </Flex>
        </SpecialFormBlock>
      )}
    </>
  );
};

export const ProductsSection = () => {
  return (
    <Card width="100%">
      <Card.Group width="100%">
        <BrandSpecialRules variant="inclusions" />
        <BrandSpecialRules variant="exclusions" />
      </Card.Group>
    </Card>
  );
};
