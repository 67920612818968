import { Link } from '@jane/shared/reefer';

import { FilterListButton, FilterListItem } from './specialsFilter.styles';

interface DeselectFilterButtonsProps {
  inList?: boolean;
  label: string;
  onClick: () => void;
}

export const DeselectFiltersButton = ({
  label,
  onClick,
  inList = true,
}: DeselectFilterButtonsProps) => {
  if (inList) {
    return (
      <FilterListItem>
        <FilterListButton onClick={onClick}>
          <Link typography="body">{label}</Link>
        </FilterListButton>
      </FilterListItem>
    );
  }

  return <Link onClick={onClick}>{label}</Link>;
};
