import type { FileWithPath } from 'react-dropzone';

import { ImagePreview } from '@jane/shared/util';

const VALID_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const MAX_IMAGE_SIZE = 10_000_000;

const validateImageFileType = (file: FileWithPath) =>
  VALID_IMAGE_TYPES.includes(file.type);

const validateImageFileSize = (file: FileWithPath) =>
  file.size <= MAX_IMAGE_SIZE;

const imageFileTypeError = 'Please upload a jpg, jpeg, or png file.';
const imageFileSizeError = 'Your upload exceeds maximum file size of 10MB.';

export interface ImageValidationParams {
  file: File;
  fileSizeErrorMessage: string;
  fileSizeValidator: (file: File) => boolean;
  fileTypeErrorMessage: string;
  fileTypeValidator: (file: File) => boolean;
}

const isValidImage = ({
  file,
  fileTypeValidator,
  fileTypeErrorMessage,
  fileSizeValidator,
  fileSizeErrorMessage,
}: ImageValidationParams): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!fileTypeValidator(file)) return resolve(fileTypeErrorMessage);
    if (!fileSizeValidator(file)) return resolve(fileSizeErrorMessage);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const dataURL = reader.result;

      if (typeof dataURL === 'string') {
        resolve('');
      } else {
        reject('File unreadable or empty');
      }
    };
  });

export const uploadBrandSpecialImage = async (file: File): Promise<string> => {
  const imageParams: ImageValidationParams = {
    file,
    fileSizeErrorMessage: imageFileSizeError,
    fileSizeValidator: validateImageFileSize,
    fileTypeErrorMessage: imageFileTypeError,
    fileTypeValidator: validateImageFileType,
  };
  const errorMessage = await isValidImage(imageParams);
  if (errorMessage) {
    throw errorMessage;
  }
  return (await ImagePreview.parse(file)).url;
};
