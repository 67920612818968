import type { SpecialStatus } from '@jane/gold-manager/types';

import { isFutureDate, isPastDate } from './time';

interface GetSpecialStatusProps {
  enabled: boolean;
  endTime: string | null;
  startTime: string | null;
}

export const getSpecialStatus = ({
  enabled,
  endTime,
  startTime,
}: GetSpecialStatusProps): SpecialStatus => {
  if (!enabled) return 'Disabled';

  if (enabled && endTime && isPastDate(endTime)) return 'Expired';

  if (enabled && startTime && isFutureDate(startTime)) return 'Upcoming';

  return 'Live';
};
