import { config } from '@jane/shared/config';

const SLUG_REPLACE_REGEX = /[^\w\d-]/g;

/**
 * Helper functions to generate paths on the Jane marketplace.
 */
export const marketplacePaths = {
  brandPage({
    id,
    normalizedName,
  }: {
    id: number;
    normalizedName: string | null;
  }) {
    if (!normalizedName) {
      return '';
    }

    return `${config.deployPublicPath}brands/${id}/${normalizedName}`;
  },

  productPage(id: number, productName: string) {
    const slug = productName.replace(SLUG_REPLACE_REGEX, '-');
    return `${config.deployPublicPath}products/${id}/${slug}`;
  },
};
