import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { getColumns } from './getColumns';
import type {
  SpecialsTableRow,
  SpecialsTableSelection,
  SpecialsTableSort,
  UseSpecialsTableProps,
} from './specialsTable.types';

export const useSpecialsTable = ({
  bulkEdit,
  onDuplicate,
  ...props
}: UseSpecialsTableProps) => {
  const [sorting, setSorting] = useState<SpecialsTableSort>([
    { desc: true, id: 'id' },
  ]);
  const [rowSelection, setRowSelection] = useState<SpecialsTableSelection>({});

  const onSetEnabled = (special: SpecialsTableRow) => {
    special.original.enabled ? console.log('disabled') : console.log('enabled');
  };
  const columns = getColumns({
    bulkEdit,
    onDuplicate,
    onSetEnabled,
  });

  return useReactTable({
    columns,
    state: { sorting, rowSelection },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id.toString(),
    ...props,
  });
};
