import { Box, Tag } from '@jane/shared/reefer';

export const ActiveFilterTag = ({
  valuesCount,
  label,
}: {
  label: string;
  valuesCount: number;
}) => {
  if (valuesCount === 0) {
    return null;
  }

  return (
    <Box
      mr={4}
      role="status"
      ariaLabel={`${valuesCount} active ${label} filter${
        valuesCount > 1 ? 's' : ''
      }`}
      aria-live="polite"
    >
      <Tag
        label={valuesCount.toString()}
        aria-hidden={true}
        background="purple-light"
        color="purple-dark"
      />
    </Box>
  );
};
